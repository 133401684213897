import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue';
import Submit from '../views/Submit.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true,
    meta:{
      title:'Home'
    }
  },
  {
    path: '/submit',
    name: 'submit',
    component: Submit,
    props: true,
    meta:{
      title:'Submit'
    }
  },
  
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to,from,next)=>{
  document.title = `${to.meta.title} | ICMS`;
  next();
});

export default router
