<template>
  <section>
    <div class="top-info-wrapper">
      <div class="top-info-bar">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4">
              <div class="info-wrapper">
                <p class="icon-wrapper">
                  <img
                    src="../assets/icon.png"
                    class="me-2"
                    width="24"
                    height="24"
                    alt=""
                  />ICMS School & College System
                </p>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="media-link text-end">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/ICMSChairman/"
                      ><i class="fa fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/ICMSChairman"
                      ><i class="fa fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCIMD0HHE9L97RqpZ3jfWG8w/featured"
                      ><i class="fa fa-youtube"></i
                    ></a>
                  </li>
                  <li>
                    <a href="#"
                      ><i class="fa fa-map-marker"></i>Opposite Peshawar
                      Board,Jamrud Road, Peshawar</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-6 col-lg-4 order-2 order-lg-1">
            <div class="single-info-wrapper">
              <div class="single-info-icon hide-mobile">
                <img src="../assets/whatsapp.png" alt="" />
              </div>
              <div class="single-info-content">
                <a href="tel:923339119145"
                  ><span>WhatsApp</span><br />+92 333-9119145</a
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4 order-1 order-lg-2">
            <div class="text-center">
              <div class="logo-wrapper">
                <img src="../assets/logo.png" alt="" />
              </div>
            </div>
          </div>
          <div class="col-6 col-lg-4 order-3 order-lg-3">
            <div class="single-info-wrapper justify-content-end">
              <div class="single-info-icon hide-mobile">
                <img src="../assets/gmail.png" alt="" />
              </div>
              <div class="single-info-content">
                <a href="mailto:info@icms.edu.pk"
                  ><span>Mail Us</span><br />info@icms.edu.pk</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="marqee-wrapper">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="marquee-content-wrapper">
              <marquee direction="ltr"
                >Online Registeration Is Open For Scholarship Merit Talent Test
                2023 For Class 5th to Class 10th and 1st Year. On line Admission
                Is Open For Class One to Class 12th.</marquee
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slider-main-wrapper">
      <swiper
        :autoplay="{
          delay: 4500,
          disableOnInteraction: false,
        }"
        :pagination="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide>
          <div class="slider-content-wrapper">
            <div class="slider-bg-img">
              <img src="../assets/slider-1.jpg" alt="" />
            </div>
            <div class="slider-img-content">
              <div class="slider-content-wrapper">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="inner-content-wrapper">
                      <div class="inner-content">
                        <h2>Apply For Year Entry Test</h2>
                        <h1>2023 <span>Admission</span></h1>
                        <a href="#FormMain">Apply Now</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="slider-content-wrapper">
            <div class="slider-bg-img">
              <img src="../assets/slider-2.jpg" alt="" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper";
import "swiper/css/pagination";
export default {
  name: "header-view",
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style>
body {
  font-family: "Manrope", sans-serif !important;
}
.top-info-wrapper {
  background: #003e78;
}
.info-wrapper {
  padding: 5px 0px;
}
.info-wrapper p {
  margin-bottom: 0px;
  color: #fff;
  text-transform: uppercase;
}
.media-link ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.media-link ul li {
  display: inline-block;
  margin-right: 10px;
}
.media-link ul li:last-child {
  margin-right: 0px;
}
.media-link ul li a {
  text-decoration: none;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
.media-link ul li a:hover {
  color: #fbe325;
}
.media-link ul li a i {
  margin-right: 5px;
}
.header-wrapper {
  padding: 20px 0px;
}
.single-info-wrapper {
  display: flex;
  align-items: center;
}
.single-info-icon img {
  width: 45px;
  margin-right: 10px;
}
.single-info-content a {
  text-decoration: none;
  color: #000;
}
.single-info-content a:hover {
  color: #000;
}
.single-info-content a span {
  font-weight: 900;
  color: #003e78;
  text-transform: uppercase;
}
.marqee-wrapper {
  padding: 5px 0px 0px 0px;
  background: #003e78;
}
.marquee-content-wrapper marquee {
  color: #fff;
}
.slider-bg-img img {
  height: 550px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}
.slider-content-wrapper {
  position: relative;
}
.slider-img-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.inner-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
}
.inner-content {
  background: rgba(0, 62, 120, 0.9);
  padding: 40px;
}
.inner-content h2 {
  font-size: 40px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
}
.inner-content h1 {
  font-size: 60px;
  color: #fbe325;
  text-transform: uppercase;
  font-weight: bolder;
}
.inner-content h1 span {
  font-size: 50px;
  font-weight: 500;
  color: #fff;
}
.inner-content a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}
.inner-content a:hover {
  color: #fbe325;
}
.swiper-pagination-bullet-active {
  background: #fbe325 !important;
}
.icon-wrapper img {
  position: relative;
  top: -2px;
}
</style>
