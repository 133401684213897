<template>
  <section class="form-main-wrapper" id="FormMain">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-title mb-5">
            <h2>1<sup>st</sup> year Entry Test Form</h2>
          </div>
        </div>
      </div>
      <!-- Form Row Start Here -->
      <div class="row">
        <div class="col-lg-12">
          <form @submit.prevent="admissionForm">
            <div class="row">
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Candidate's Name<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      id="StudentName"
                      placeholder="Enter Candidate's Name"
                      class="form-control"
                      :class="{ error: !isStudentNameValid }"
                      v-model="StudentName"
                      @blur="validateStudentName"
                      @keydown="validateInputs"
                    />
                    <div v-if="!isStudentNameValid">
                      <label class="error-label"
                        >Please Enter Candidate's Name</label
                      >
                    </div>
                    <div v-if="showStudentNameErrorMessage" class="error-label">
                      Numeric & Special Characters are not allowed.
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/graduated.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Father's Name<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="text"
                      id="FatherName"
                      placeholder="Enter Father's Name"
                      class="form-control"
                      :class="{ error: !isFatherNameValid }"
                      v-model="FatherName"
                      @blur="validateFatherName"
                      @keydown="validateInputs"
                    />
                    <div v-if="!isFatherNameValid">
                      <label class="error-label"
                        >Please Enter Father Name</label
                      >
                    </div>
                    <div v-if="showFatherNameErrorMessage" class="error-label">
                      Numeric & Special Characters are not allowed.
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/man.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Date of Birth</label>
                  <div class="position-relative">
                    <input type="date" class="form-control" v-model="DOB" />
                    <div class="input-icons">
                      <span><img src="../assets/birthday.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Gender<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="GenderIdFk"
                      :class="{ error: !isGenderIdFkValid }"
                      @blur="validateGenderIdFk"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in GenderOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isGenderIdFkValid">
                      <label class="error-label"
                        >Please Select Your Gender</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span
                        ><img src="../assets/gender-fluid.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Nationality<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Nationality"
                      :class="{ error: !isNationalityValid }"
                      @blur="validateNationality"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in NationalityOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isNationalityValid">
                      <label class="error-label"
                        >Please Choose Your Nationality</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span
                        ><img src="../assets/united-nations.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Religion<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Religion"
                      :class="{ error: !isReligionValid }"
                      @blur="validateReligion"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in ReligionOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isReligionValid">
                      <label class="error-label"
                        >Please Choose Your Religion</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/mosque.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Domicile District<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="DomicileDistrict"
                      :class="{ error: !isDomicileDistrictValid }"
                      @blur="validateDomicileDistrict"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in DomicileDistrictOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isDomicileDistrictValid">
                      <label class="error-label"
                        >Please Choose Your Domicile District</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span
                        ><img src="../assets/certificate.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Admission Class<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="AdmissionClass"
                      :class="{ error: !isAdmissionClassValid }"
                      @blur="validateAdmissionClass"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in AdmissionClassOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isAdmissionClassValid">
                      <label class="error-label"
                        >Please Choose Admission Class</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/id-card.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">SSC Board<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Board"
                      :class="{ error: !isBoardValid }"
                      @blur="validateBoard"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in BoardOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isBoardValid">
                      <label class="error-label"
                        >Please Choose Your SSC Board</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/school.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Campus<sup>*</sup></label>
                  <div class="position-relative">
                    <select
                      class="form-select"
                      v-model="Campus"
                      :class="{ error: !isCampusValid }"
                      @blur="validateCampus"
                    >
                      <option value="">Choose...</option>
                      <option
                        v-for="option in CampusOptions"
                        :key="option.label"
                        :value="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                    <div v-if="!isCampusValid">
                      <label class="error-label"
                        >Please Choose Your Campus</label
                      >
                    </div>
                    <div class="input-icons selct-icon">
                      <span><img src="../assets/school.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Mobile Number<sup>*</sup></label>
                  <div class="position-relative">
                    <input
                      type="tel"
                      id="MobileNumber"
                      v-model="MobileNumber"
                      placeholder="Enter Mobile Number"
                      class="form-control"
                      :class="{ error: !isMobileNumberValid }"
                      @blur="validateMobileNumber"
                      :maxlength="11"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isMobileNumberValid">
                      <label class="error-label"
                        >Please Enter a Valid 11-digit Phone Number</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/call.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">WhatsApp Number</label>
                  <div class="position-relative">
                    <input
                      type="tel"
                      id="WhatsAppNo"
                      v-model="WhatsAppNo"
                      placeholder="Enter WhatsApp Number"
                      class="form-control"
                      :class="{ error: !isWhatsAppNoValid }"
                      @blur="validateWhatsAppNo"
                      :maxlength="11"
                      @keydown="onlyNumeric"
                    />
                    <div v-if="!isWhatsAppNoValid">
                      <label class="error-label"
                        >Please Enter a Valid 11-digit Phone Number</label
                      >
                    </div>
                    <div class="input-icons">
                      <span><img src="../assets/whatsapp.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Email Id</label>
                  <div class="position-relative">
                    <input
                      type="email"
                      v-model="Email"
                      placeholder="Enter Email ID"
                      class="form-control"
                    />
                    <div class="input-icons adjustment">
                      <span><img src="../assets/gmail.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Facebook</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="Facebook"
                      placeholder="Enter Facebook ID"
                      class="form-control"
                    />
                    <div class="input-icons adjustment">
                      <span><img src="../assets/facebook.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Last Attended School</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="LastAttendSchool"
                      placeholder="Enter Last Attended School Name"
                      class="form-control"
                    />
                    <div class="input-icons adjustment">
                      <span><img src="../assets/school.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Passing Year</label>
                  <div class="position-relative">
                    <input
                      type="month"
                      v-model="PassingYear"
                      placeholder="Enter Passing Year"
                      class="form-control"
                    />
                    <div class="input-icons adjustment">
                      <span><img src="../assets/calendar.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Marks in Last Class</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="Marks"
                      placeholder="Enter Marks in Last Class"
                      class="form-control"
                    />
                    <div class="input-icons adjustment">
                      <span
                        ><img src="../assets/number-blocks.png" alt=""
                      /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Date of Apply</label>
                  <div class="position-relative">
                    <input type="date" class="form-control" v-model="DOA" />
                    <div class="input-icons">
                      <span><img src="../assets/birthday.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Present Address</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="PresentAddress"
                      placeholder="Enter Present Address"
                      class="form-control"
                    />
                    <div class="input-icons">
                      <span><img src="../assets/map.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="singleInput-Wrapper">
                  <label class="d-block">Permanent Address</label>
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="PermanentAddress"
                      placeholder="Enter Permanent Address"
                      class="form-control"
                    />
                    <div class="input-icons">
                      <span><img src="../assets/map.png" alt="" /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mb-3 mt-4">
                <div class="row justify-content-center">
                  <div class="col-lg-3">
                    <div class="submitbtn">
                      <button type="submit">Submit Form</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- Form Row End's Here -->
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Home-View",
  data() {
    return {
      StudentName: "",
      showStudentNameErrorMessage: false,
      isStudentNameValid: true,
      FatherName: "",
      isFatherNameValid: true,
      showFatherNameErrorMessage: false,
      DOB: "",
      GenderIdFk: "",
      isGenderIdFkValid: true,
      Nationality: "",
      isNationalityValid: true,
      Religion: "",
      isReligionValid: true,
      DomicileDistrict: "",
      isDomicileDistrictValid: true,
      AdmissionClass: "",
      isAdmissionClassValid: true,
      Campus: "",
      isCampusValid: true,
      Board: "",
      isBoardValid: true,
      MobileNumber: "",
      isMobileNumberValid: true,
      WhatsAppNo: "",
      isWhatsAppNoValid: true,
      Email: "",
      Facebook: "",
      LastAttendSchool: "",
      PassingYear: "",
      Marks: "",
      PresentAddress: "",
      PermanentAddress: "",
      DOA: "",
      GenderOptions: [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
      ],
      NationalityOptions: [
        { label: "Pakistan", value: "pakistan" },
        { label: "Other", value: "other" },
      ],
      ReligionOptions: [
        { label: "Islam", value: "islam" },
        { label: "Other", value: "other" },
      ],
      DomicileDistrictOptions: [
        { label: "Peshawar", value: "peshawar" },
        { label: "Nowshera", value: "nowshera" },
        { label: "Charsadda", value: "charsadda" },
        { label: "Chitral", value: "chitral" },
        { label: "Upper Dir", value: "upper dir" },
        { label: "Lower Dir", value: "lower dir" },
        { label: "Malakand", value: "malakand" },
        { label: "Buner", value: "buner" },
        { label: "Shangla", value: "shangla" },
        { label: "Kohistan", value: "kohistan" },
        { label: "Batagram", value: "batagram" },
        { label: "Mansehra", value: "mansehra" },
        { label: "Abbottabad", value: "abbottabad" },
        { label: "Haripur", value: "haripur" },
        { label: "Swabi", value: "swabi" },
        { label: "Mardan", value: "mardan" },
        { label: "Kohat", value: "kohat" },
        { label: "Hangu", value: "hangu" },
        { label: "Bannu", value: "bannu" },
        { label: "Laki Marwat", value: "laki marwat" },
        { label: "Tank", value: "Tank" },
        { label: "D.I Khan", value: "D.I khan" },
        { label: "Khurram Agency", value: "khurram agency" },
        { label: "Khyber Agency", value: "khyber agency" },
        { label: "Karak", value: "karak" },
        { label: "South Waziristan", value: "south waziristan" },
        { label: "North Waziristan", value: "north waziristan" },
        { label: "Mohamand Agency", value: "Mohamand agency" },
        { label: "Bajur Agency", value: "bajur agency" },
        { label: "Orakzai Agency", value: "orakzai agency" },
        { label: "FR Areas", value: "FR areas" },
        { label: "Other", value: "other" },
      ],
      AdmissionClassOptions: [
        { label: "F.Sc (Medical)", value: "F.Sc (Medical)" },
        { label: "F.Sc (Engineering)", value: "F.Sc (Engineering)" },
        { label: "FCS (Computer Science)", value: "FCS (Computer Science)" },
      ],
      CampusOptions: [
        { label: "ICMS Girls College Hayatabad", value: "ICMS Girls College Hayatabad" },
        { label: "ICMS College System Khyber For Boys", value: "ICMS College System Khyber For Boys" },
        { label: "ICMS College System Warsak", value: "ICMS College System Warsak" },
        { label: "Nowshera College System Boys", value: "Nowshera College System Boys" },
        { label: "Nowshera College System for Girls", value: "Nowshera College System for Girls" },
        { label: "ICMS College System Charsadda", value: "ICMS College System Charsadda" },
      ],
      BoardOptions: [
        { label: "Peshawar Board", value: "Peshawar Board" },
        { label: "Kohat Board", value: "Kohat Board" },
        { label: "Mardan Board", value: "Mardan Board" },
        { label: "DI Khan", value: "DI Khan" },
        { label: "Swat Board", value: "Swat Board" },
        { label: "Malakand Board", value: "Malakand Board" },
        { label: "Abbottabad Board", value: "Abbottabad Board" },
        { label: "Bannu Board", value: "Bannu Board" },
        { label: "Federal Board", value: "Federal Board" },
        { label: "Rawalpindi Board", value: "Rawalpindi Board" },
        { label: "other", value: "other" },
      ],
      responseData: null,
    };
  },

  methods: {
    validateInputs(event) {
      const keyCode = event.keyCode || event.which;
      const inputField = event.target.getAttribute("id");

      // Allow backspace and delete keys
      if (keyCode === 8 || keyCode === 46) {
        return;
      }

      // Block numeric keys, special characters, and numeric pad keys
      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        (keyCode >= 186 && keyCode <= 192) ||
        (keyCode >= 219 && keyCode <= 222)
      ) {
        event.preventDefault();
        if (inputField === "StudentName") {
          this.showStudentNameErrorMessage = true;
          return;
        }
        if (inputField === "FatherName") {
          this.showFatherNameErrorMessage = true;
          return;
        }
      }
      // Allow alphabetic characters and spaces
      if (
        (keyCode >= 65 && keyCode <= 90) ||
        (keyCode >= 97 && keyCode <= 122) ||
        keyCode === 32
      ) {
        if (inputField === "StudentName") {
          this.showStudentNameErrorMessage = false;
          return;
        }
        if (inputField === "FatherName") {
          this.showFatherNameErrorMessage = false;
          return;
        }
      }
      // Prevent any other keys from being entered
      // event.preventDefault();
    },
    onlyNumeric(event) {
      const keyCode = event.keyCode || event.which;

      if (
        (keyCode >= 48 && keyCode <= 57) ||
        (keyCode >= 96 && keyCode <= 105) ||
        keyCode === 9 ||
        keyCode === 8
      ) {
        return true;
      }
      // Block all other keys
      event.preventDefault();
      return false;
    },
    validateStudentName() {
      this.isStudentNameValid = !!this.StudentName;
    },
    validateFatherName() {
      this.isFatherNameValid = !!this.FatherName;
    },
    validateGenderIdFk() {
      this.isGenderIdFkValid = !!this.GenderIdFk;
    },
    validateNationality() {
      this.isNationalityValid = !!this.Nationality;
    },
    validateReligion() {
      this.isReligionValid = !!this.Religion;
    },
    validateDomicileDistrict() {
      this.isDomicileDistrictValid = !!this.DomicileDistrict;
    },
    validateAdmissionClass() {
      this.isAdmissionClassValid = !!this.AdmissionClass;
    },
    validateBoard() {
      this.isBoardValid = !!this.Board;
    },
    validateCampus() {
      this.isCampusValid = !!this.Campus;
    },
    validateMobileNumber() {
      // this.isMobileNumberValid = /^[0-9]{11}$/.test(this.MobileNumber);
      this.isMobileNumberValid = !!this.MobileNumber;
    },
    validateWhatsAppNo() {
      // this.isWhatsAppNoValid = /^[0-9]{11}$/.test(this.WhatsAppNo);
      this.isWhatsAppNoValid = !!this.WhatsAppNo;
    },

    formReset() {
      (this.StudentName = ""),
        (this.FatherName = ""),
        (this.DOB = ""),
        (this.GenderIdFk = ""),
        (this.Nationality = ""),
        (this.Religion = ""),
        (this.DomicileDistrict = ""),
        (this.AdmissionClass = ""),
        (this.Board = ""),
        (this.Campus = ""),
        (this.MobileNumber = ""),
        (this.WhatsAppNo = ""),
        (this.Email = ""),
        (this.Facebook = ""),
        (this.LastAttendSchool = ""),
        (this.Marks = ""),
        (this.PresentAddress = ""),
        (this.PassingYear = ""),
        (this.PermanentAddress = ""),
        (this.DOA = "");
    },
    admissionForm() {
      this.validateStudentName();
      this.validateFatherName();
      this.validateGenderIdFk();
      this.validateNationality();
      this.validateReligion();
      this.validateDomicileDistrict();
      this.validateAdmissionClass();
      this.validateBoard();
      this.validateCampus();
      this.validateMobileNumber();
      this.validateWhatsAppNo();
      if (
        !this.isStudentNameValid ||
        !this.isFatherNameValid ||
        !this.isGenderIdFkValid ||
        !this.isNationalityValid ||
        !this.isReligionValid ||
        !this.isDomicileDistrictValid ||
        !this.isAdmissionClassValid ||
        !this.isBoardValid ||
        !this.isCampusValid ||
        !this.MobileNumber ||
        !this.isWhatsAppNoValid
      ) {
        return;
      } else {
        var model = {
          MobileNumber: this.MobileNumber,
          Email: this.Email,
          CandidateName: this.StudentName,
          Gender: this.GenderIdFk,
          FatherName: this.FatherName,
          DOB: this.DOB,
          Nationality: this.Nationality,
          Religion: this.Religion,
          DomicileDistrict: this.DomicileDistrict,
          AdmissionClass: this.AdmissionClass,
          Board: this.Board,
          Campus: this.Campus,
          WhatsAppNo: this.WhatsAppNo,
          Facebook: this.Facebook,
          LastAttendSchool: this.LastAttendSchool,
          Marks: this.Marks,
          PresentAddress: this.PresentAddress,
          PassingYear: this.PassingYear,
          PermanentAddress: this.PermanentAddress,
          DOA: this.DOA,
        };
        // console.log(model);
        const auth = {
          username: "icms",
          password: "icms@Cyberasol",
        };
        const headers = {
          "Content-Type": "application/json",
        };
        axios
          .post(
            "https://icms.cyberasol.com/Api/Api/InquiryWeb/AddEntryTestForm",
            model,
            {
              auth: auth,
              headers: headers,
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data);
              this.responseData = response.data;
              this.formReset();
              this.$router
                .push({ path: "/submit", query: { data: this.responseData } })
                .then(() => {
                  window.scrollTo(100, 600);
                });
            }
          });
      }
    },
  },
};
</script>

<style>
.error {
  color: red;
}
.submitbtn button {
  width: 100%;
  padding: 14px 0px;
  border: none;
  text-transform: uppercase;
  border-radius: 0px;
  font-weight: 700;
  background: #003e78;
  color: #fff;
  transition: 0.3s ease-in-out;
}
.submitbtn button:hover {
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
  box-shadow: 0px 1px 22px -2px rgba(0, 62, 120, 0.48);
}
.form-title {
  text-align: center;
}
.form-title h2 {
  text-transform: capitalize;
  font-weight: 900;
  font-size: 30px;
  line-height: 42px;
}
.form-main-wrapper {
  padding: 70px 0px;
}
.singleInput-Wrapper label {
  text-transform: uppercase;
  margin-bottom: 8px;
  font-weight: 700;
  letter-spacing: 0.03em;
}
.singleInput-Wrapper label sup {
  color: red;
  padding-left: 5px;
}
.singleInput-Wrapper input,
.singleInput-Wrapper select,
.singleInput-Wrapper textarea {
  padding: 12px 10px 12px 60px;
  /* background: rgba(0, 62, 120, 0.06); */
  font-weight: 600;
  width: 100%;
}
.singleInput-Wrapper input::placeholder,
.singleInput-Wrapper select::placeholder,
.singleInput-Wrapper textarea::placeholder {
  font-size: 15px;
  font-weight: 400;
}
.singleInput-Wrapper input:focus,
.singleInput-Wrapper select:focus,
.singleInput-Wrapper textarea:focus {
  box-shadow: none;
  border-color: #003e78;
  /* background: rgba(0, 62, 120, 0.1); */
}
.input-icons {
  position: absolute;
  top: 1px;
  left: 0.8px;
  width: 52px;
  height: 48px;
  /* background: #fff; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.selct-icon {
  top: 1px;
  left: 0.8px;
  width: 52px;
  height: 48px;
}
.input-icons.adjustment {
  top: 1px;
  height: 48px;
}
.input-icons img {
  width: 30px;
  height: 30px;
}
.note-wrapper h2 {
  font-size: 24px;
  font-weight: 900;
  text-transform: uppercase;
}
.note-wrapper h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.error-label {
  color: red;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  font-size: 13px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .top-info-wrapper {
    padding: 5px 0px;
  }
  .info-wrapper {
    text-align: center;
  }
  .media-link.text-end {
    text-align: center !important;
  }
  .hide-mobile {
    display: none;
  }
  .single-info-wrapper {
    margin-top: 10px;
  }
  .inner-content h2 {
    font-size: 20px;
  }
  .inner-content h1 {
    font-size: 40px;
  }
  .footer-top {
    padding-top: 300px !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner {
    border-left: none !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner:before {
    content: none !important;
  }
  .rs-footer
    .footer-contact-desc
    div[class*="col-"]
    + div[class*="col-"]
    .contact-inner::after {
    content: none !important;
  }
  .footer-media-links.text-end {
    text-align: center !important;
  }
}
</style>
